import { Injectable } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { tabNom, conf } from '../../assets/datas/conf';

import * as Highcharts from 'highcharts';
import { event } from 'jquery';
/* HIGHTCHARTS *****************
https://github.com/highcharts/highcharts-angular (pour exemple implémentation angular)
https://www.highcharts.com/demo/annotations ( pour anotations )
https://www.highcharts.com/demo/line-time-series  (pour dégradé)
https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/plotoptions/series-events-legenditemclick/ (pour afficher/masquer courbe)
******************************** */


export type tempsModel = {
  id: string,
  type: string,
  mc: string,
  a: number,
  q: number,
  minute: number,
  action: string,
  name: string
}

export type persoModel = {
  id: number,
  genre: string,
  age: number,
  etage: number,///tranche d'age pour svg
  poids: number,
  taille: number,
  nom: string, 
  persoNum: number,
  temps?: tempsModel[]
}

@Injectable({
  providedIn: 'root'
})
export class AnpaaService {

  public version = '3.2';

  public PERSOS:persoModel[] = [];
  public perso:persoModel;
  public ETAPE = 0;
  public TEMPS = [];
  public ecartMinute = 10;
  public ecartPixel = 50;
  public lex = 0;
  public deplacementMinute = this.ecartMinute * 5;
  public time = 720;
  public largeur = 1220;
  public centre = 600;
  public jour = 1;
  public interfaceGauche = window.matchMedia("(max-width: 1024px)").matches ? 200 : 356;
  public afficheCourbe = false;

  public isSafari = false;

  public countPerso = 0;

  public oneToOneFlag;
  public runOutsideAngularFlag;
  public chartCallback;
  public chartConstructor;

  //////////// GRAPH //////////

  public Highcharts: typeof Highcharts = Highcharts;
  public updateFlag = false;

  public chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: "transparent",
      type: 'area',
    },
    legend: {
      itemStyle: {
         color: 'white'
      },
    },
    xAxis: {
      //width: '95%',
      type: 'datetime',
      //dateTimeLabelFormats: {
      //  //month: '%b \'%y',
      //  //day: '%e. %b',
      //  //year: '%Y'
      //  // day: '%d %b %Y %I:%M %P'
      //  hour: '%I %p',
      //  minute: '%I:%M %p',
      //  second: '%I:%M:%S %p'
      //},
      title: {
        text: ''
      },
      lineColor: "transparent",
      tickColor: "transparent",
      labels: {
        format: '{value:%H:%M}',
        style: {
           color: "white",
           fill: 'white',
           fontSize: "14px",
           fontWeight: "900"
        }
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      tickInterval: 0.5,
      lineColor: "rgba(255, 255, 255, 0)",
      tickColor: "white",
      labels: {
         style: {
            color: "white",
            fontSize: "14px",
            fontWeight: "900"
         }
      },
      plotBands: [
      ],
      plotLines: [
      ]
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        enableMouseTracking: true
      },
      area: {
        marker: {
          enabled: true,
          radius: 1,
        },
        dataLabels: {
          enabled: false
        },
      },
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        //Number(this.x)/60/1000
        //var thisTIme = new Date(this.x).toLocaleTimeString();
        var thisTIme = new Date(this.x);
        thisTIme.setHours(thisTIme.getHours() - 1);
        return  thisTIme.toLocaleTimeString().substring(0,5) + '<br>' +
                String(this.y).substring(0,4) + ' g/l';
      },
      backgroundColor: '#1c1c1c',
      borderColor: 'black',
      borderWidth: 1,
      padding: 10,
      borderRadius: 10,
      style: {
        color: 'white'
      },
      shadow: true
    },
    series: [
    ]
  };

  constructor(
    public platform : Platform,
    public popoverController: PopoverController
  ) { }

  checkCentre() {
    this.largeur =  this.platform.width();
    this.centre = (this.interfaceGauche+(this.largeur-this.interfaceGauche)/2);
    console.log('checkCentre',this.centre,this.platform.width());
    this.deplaceTimeline();
  }
  
  deplaceTimeline(){
    let ajustement  = -7250;
    this.lex =  ( this.time / this.ecartMinute *  this.ecartPixel )*-1  +  this.ecartPixel/-2 + this.centre;
    //this.lex = this.lex *-1;
    console.log('deplaceTimeline:' , this.lex, ajustement );
    this.checkJour();
    //* this.ecartMinute * this.ecartPixel;
    //document.getElementById('timeline').style.left = '40px';
  }

  checkJour(){
    if(this.time >=0 && this.time<1440){
      this.jour = 1;
    }else if(this.time >=1440 && this.time<2880){
      this.jour = 2;
    }else if(this.time >=2880 && this.time<4320){
      this.jour = 3;
    }
    //console.log('Jour: ', this.jour, 'Temps centre: ', this.time);
  }
  
  ajoutPerso(genre = 'H', prevPersoTemps = []){

    let taille;
    let poids;
    if(genre == 'H'){
      taille = 176;
      poids = 78;
    }else{
      taille = 165;
      poids = 65;
    }
    
    console.log('ajoutPerso');
    console.log('prevPersoTemps: ', prevPersoTemps);
    let perso:persoModel =  {
      id: this.PERSOS.length,
      nom: tabNom[Math.floor(Math.random()*tabNom.length-1)][genre],
      genre,
      age: 35,
      etage: 18,
      poids: poids,
      taille: taille,
      persoNum: this.countPerso,
      //temps: []
      temps: [...prevPersoTemps]
    };
    this.countPerso++;
    //if(perso.id == 1 && perso.temps.length <= 0){
    //  perso.temps = prevPersoTemps;
    //}
    this.perso = perso; //perso sélectionné
    this.TEMPS = [];
    this.PERSOS.push(perso);
    //this.PERSOS[this.countPerso].temps = prevPersoTemps;
    this.initTemps();
    console.log('--PERSO: ', perso);
    console.log('--PERSOS: ', this.PERSOS);
  }

  afficheHeure(minute:number):String {
    let j:number=Math.floor(minute/1440);//1440 = 24h  
    minute = minute-(j*1440);// j = jours
    let h:number=Math.floor(minute/60);
    minute = minute-(h*60);
    return this.doubleDigitFormat(h)+":"+this.doubleDigitFormat(minute);
  }

  afficheMinute(minute:number) {
    let j:number=Math.floor(minute/1440);//1440 = 24h  
    return minute = minute-(j*1440);// j = jours
    //let h:number=Math.floor(minute/60);
    //minute = minute-(h*60);
    //return this.doubleDigitFormat(h)+":"+this.doubleDigitFormat(minute);
  }

  doubleDigitFormat(nombre:number) {
    nombre = Number(nombre);
    if(nombre < 10) {
      return ("0" + nombre);
    }
    return String(nombre);
  }

  initTemps(){
    console.log('initTemps');
    this.TEMPS = [];
    setTimeout(() => {
      for(let i = 0; i < 24*60/this.ecartMinute*4; i++){
        this.TEMPS.push({
          minute: i*this.ecartMinute,
          i
        })
      }
      this.time = 720;//720
      console.log('this.TEMPS : ', this.TEMPS);
      let tabTimeOpacity = {
        0 : 0,
        720 : 100
      }
    }, 1000);
  }


  verifCrans(){
    // replace des icones sur les crans
  }

  changePerso(){
    console.log('changePero()');
    this.perso = this.PERSOS[this.perso.id == 0 ? 1 : 0];
    //console.log('ID: ',this.perso.id, 'NUM: ', this.perso.persoNum);
    this.TEMPS = [];
    this.time = 720;
    this.changeSky();
    this.initTemps();
  }


  verifHTML() {
    if(this.ETAPE == 1){
      document.getElementById('timeline_wrap').style.bottom = '30px';
      document.getElementById('timeline_wrap').style.display = 'block';
    }else{
      document.getElementById('timeline_wrap').style.bottom = '-230px';
      setTimeout(() => {
        document.getElementById('timeline_wrap').style.display = 'none';
      }, 700);
    }
  }

  reset(){
    this.ETAPE = 0;
    this.PERSOS = [];
    this.TEMPS = [];
    this.time = 720;
    this.countPerso = 0;
    this.ajoutPerso();
    this.verifHTML();
    this.changeSky();
    //this.initTemps();
  }

  changeSky(){
    let cielOpacity: number;
    let minutes = this.afficheMinute(this.time);
    let sunPos = ((400/1440)*minutes)-150; // % sur 200
    console.log(sunPos);
    if(minutes<=720){ // SI entre minuit et midi (le matin)
      cielOpacity = minutes/720;
      console.log('MATIN - time: '+this.time+' | cielOpacity: '+cielOpacity);
    }else{ // SI entre minuit et midi (l'après-midi)
      cielOpacity = (1440-minutes)/720;
    }
    let time = this.time;
    if(time >=1420 && time<=1470 || time >=2830 && time<=2880){
      document.getElementById('sun').style.display = 'none';
    }else{
      document.getElementById('sun').style.display = 'block';
    }
    document.getElementById('ciel_jour').style.opacity = cielOpacity.toString();
    document.getElementById('sun').style.left = 'calc('+sunPos.toString()+'% - 75px)';
  }

  checkPlatform(){
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

}
