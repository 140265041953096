export const conf = 
	{
		"repas": [
			{"id":"repas_sandwich" , "type":"r", "mc": "repas_sandwich", "a":0, "q":600, "action":"", "name":"Repas léger"},
			{"id":"repas_steack_frites" , "type":"r", "mc": "repas_steack_frites", "a":0, "q":400, "action":"", "name":"Repas complet"}
		],

		"bar": [
			{"id":"soft_orange" , "type":"v", "mc": "soft_orange", "a":0, "q":25, "action":"enabled", "name":"Soft à l'orange"},
			{"id":"bar_demi" , "type":"v", "mc": "verre_demi", "a":5, "q":25, "action":"enabled", "name":"Demi"},
			{"id":"bar_pinte" , "type":"v", "mc": "verre_pinte", "a":5, "q":50, "action":"enabled", "name":"Pinte"},
			{"id":"bar_demi_fort" , "type":"v", "mc": "verre_demi_fort", "a":6.5, "q":25, "action":"enabled", "name":"Demi de bière forte"},
			{"id":"bar_pinte_forte" , "type":"v", "mc": "verre_pinte_forte", "a":6.5, "q":50, "action":"enabled", "name":"Pinte de bière forte"},
			{"id":"bar_canette_forte" , "type":"v", "mc": "verre_cannette_forte", "a":8.6, "q":50, "action":"enabled", "name":"Canette de bière forte"},
			{"id":"bar_vin" , "type":"v", "mc": "verre_vin", "a":12.5, "q":10, "action":"enabled", "name":"Verre de vin"},
			{"id":"bar_pastis" , "type":"v", "mc": "verre_pastis", "a":45, "q":2.5, "action":"enabled", "name":"Verre de Pastis"},
			{"id":"bar_porto" , "type":"v", "mc": "verre_porto", "a":18, "q":7, "action":"enabled", "name":"Verre de Porto"},
			{"id":"bar_whisky" , "type":"v", "mc": "verre_whisky", "a":40, "q":3, "action":"enabled", "name":"Verre de Whisky"},
			{"id":"bar_cocktail" , "type":"v", "mc": "verre_cocktail", "a":12, "q":10, "action":"enabled", "name":"Cocktail"},
			{"id":"shot_vodka" , "type":"v", "mc": "shot_vodka", "a":40, "q":3, "action":"enabled", "name":"Shot de vodka"},
			{"id":"bar_champagne" , "type":"v", "mc": "verre_champagne", "a":12, "q":10, "action":"enabled", "name":"Verre de Champagne"}
		],
		"maison": [
			{"id":"soft_orange" , "type":"v", "mc": "soft_orange", "a":0, "q":25, "action":"", "name":"Soft à l'orange"},
			{"id":"maison_demi" , "type":"v", "mc": "verre_demi", "a":5, "q":25, "action":"", "name":"Demi"},
			{"id":"maison_pinte" , "type":"v", "mc": "verre_pinte", "a":5, "q":50, "action":"", "name":"Pinte"},
			{"id":"maison_demi_fort" , "type":"v", "mc": "verre_demi_fort", "a":6.5, "q":25, "action":"", "name":"Demi de bière forte"},
			{"id":"maison_pinte_forte" , "type":"v", "mc": "verre_pinte_forte", "a":6.5, "q":50, "action":"", "name":"Pinte de bière forte"},
			{"id":"bar_canette_forte" , "type":"v", "mc": "verre_cannette_forte", "a":8.6, "q":50, "action":"", "name":"Canette de bière forte"},
			{"id":"maison_vin" , "type":"v", "mc": "verre_vin", "a":12.5, "q":16, "action":"", "name":"Verre de vin"},
			{"id":"maison_pastis" , "type":"v", "mc": "verre_pastis", "a":45, "q":5, "action":"", "name":"Verre de Pastis"},
			{"id":"bar_porto" , "type":"v", "mc": "verre_porto", "a":18, "q":14, "action":"", "name":"Verre de Porto"},
			{"id":"maison_whisky" , "type":"v", "mc": "verre_whisky", "a":50, "q":6, "action":"", "name":"Verre de Whisky"},
			{"id":"maison_cocktail" , "type":"v", "mc": "verre_cocktail", "a":12, "q":20, "action":"", "name":"Cocktail"},
			{"id":"shot_vodka" , "type":"v", "mc": "shot_vodka", "a":40, "q":6, "action":"", "name":"Shot de vodka"},
			{"id":"bar_champagne" , "type":"v", "mc": "verre_champagne", "a":12, "q":12, "action":"", "name":"Verre de Champagne"}
		]
	
		/*
		"bar": [
			{"id":"bar_demi" , "type":"v", "mc": "verre_demi", "a":5, "q":25, "action":"enabled", "name":"Demi"},
			{"id":"bar_pinte" , "type":"v", "mc": "verre_pinte", "a":5, "q":50, "action":"enabled", "name":"Pinte"},
			{"id":"bar_demi_fort" , "type":"v", "mc": "verre_demi_fort", "a":6.5, "q":25, "action":"enabled", "name":"Demi de bière forte"},
			{"id":"bar_pinte_forte" , "type":"v", "mc": "verre_pinte_forte", "a":6.5, "q":50, "action":"enabled", "name":"Pinte de bière forte"},
			{"id":"bar_canette_forte" , "type":"v", "mc": "verre_cannette_forte", "a":8.6, "q":50, "action":"enabled", "name":"Canette de bière forte"},
			{"id":"bar_vin" , "type":"v", "mc": "verre_vin", "a":12.5, "q":10, "action":"enabled", "name":"Verre de vin"},
			{"id":"bar_vin" , "type":"v", "mc": "verre_vin_blanc", "a":12.5, "q":10, "action":"enabled", "name":"Verre de vin blanc"},
			{"id":"bar_rose" , "type":"v", "mc": "verre_vin_rose", "a":12.5, "q":10, "action":"enabled", "name":"Verre de vin rosé"},
			{"id":"bar_pastis" , "type":"v", "mc": "verre_pastis", "a":45, "q":2.5, "action":"enabled", "name":"Verre de Pastis"},
			{"id":"bar_porto" , "type":"v", "mc": "verre_porto", "a":18, "q":7, "action":"enabled", "name":"Verre de Porto"},
			{"id":"bar_whisky" , "type":"v", "mc": "verre_whisky", "a":40, "q":3, "action":"enabled", "name":"Verre de Whisky"},
			{"id":"bar_cocktail" , "type":"v", "mc": "verre_cocktail", "a":12, "q":10, "action":"enabled", "name":"Cocktail"},
			{"id":"bar_champagne" , "type":"v", "mc": "verre_champagne", "a":12, "q":10, "action":"enabled", "name":"Verre de Champagne"}
		],
		"maison": [
			{"id":"maison_demi" , "type":"v", "mc": "verre_demi", "a":5, "q":25, "action":"", "name":"Demi"},
			{"id":"maison_pinte" , "type":"v", "mc": "verre_pinte", "a":5, "q":50, "action":"", "name":"Pinte"},
			{"id":"maison_demi_fort" , "type":"v", "mc": "verre_demi_fort", "a":6.5, "q":25, "action":"", "name":"Demi de bière forte"},
			{"id":"maison_pinte_forte" , "type":"v", "mc": "verre_pinte_forte", "a":6.5, "q":50, "action":"", "name":"Pinte de bière forte"},
			{"id":"bar_canette_forte" , "type":"v", "mc": "verre_cannette_forte", "a":8.6, "q":50, "action":"", "name":"Canette de bière forte"},
			{"id":"maison_vin" , "type":"v", "mc": "verre_vin", "a":12.5, "q":16, "action":"", "name":"Verre de vin"},
			{"id":"bar_vin" , "type":"v", "mc": "verre_vin_blanc", "a":12.5, "q":16, "action":"", "name":"Verre de vin blanc"},
			{"id":"bar_rose" , "type":"v", "mc": "verre_vin_rose", "a":12.5, "q":16, "action":"", "name":"Verre de vin rosé"},
			{"id":"maison_pastis" , "type":"v", "mc": "verre_pastis", "a":45, "q":5, "action":"", "name":"Verre de Pastis"},
			{"id":"bar_porto" , "type":"v", "mc": "verre_porto", "a":18, "q":14, "action":"", "name":"Verre de Porto"},
			{"id":"maison_whisky" , "type":"v", "mc": "verre_whisky", "a":50, "q":6, "action":"", "name":"Verre de Whisky"},
			{"id":"maison_cocktail" , "type":"v", "mc": "verre_cocktail", "a":12, "q":20, "action":"", "name":"Cocktail"},
			{"id":"bar_champagne" , "type":"v", "mc": "verre_champagne", "a":12, "q":12, "action":"", "name":"Verre de Champagne"}
		]
		*/
	}
;
export const tabNom = [
{F:"Anne",H:"Marius"},
{F:"Marie",H:"Jules"},
{F:"Elisabeth",H:"Eric"},
{F:"Clémence",H:"Dominique"},
{F:"Louise",H:"Jean"},
{F:"Chloé",H:"Farid"},
{F:"Lucile",H:"Mathieu"},
{F:"Emmanuelle",H:"Jérôme"},
{H:"Théo",F:"Coralie"},
{H:"Raphaël",F:"Inès"},
{H:"Mohamed",F:"Manon"},
{H:"Florian",F:"Camille"},
{H:"Nathan",F:"Emilie"},
{H:"Sacha",F:"Emma"},
{H:"Hugo",F:"Ophélia"},
{H:"Romain",F:"Pacale"},
{H:"Thomas",F:"Margaux"},
{H:"Corentin",F:"Chloé"},
{H:"Lucas",F:"Gaëlle"},
{H:"Hicham",F:"Léa"},
{H:"Milo",F:"Mathilde"},
{H:"Maxime",F:"Audrey"},
{H:"Bastien",F:"Nina"},
{H:"Benoit",F:"Lina"},
{H:"Tristan",F:"Aurélie"},
{H:"Ludovic",F:"Solène"},
{H:"Mathis",F:"Eloïse"},
{H:"Ilyes",F:"Nadia"},
{H:"Nicolas",F:"Laura"},
{H:"Xavier",F:"Sarah"},
{H:"Valentin",F:"Lena"},
{H:"Erwan",F:"Salomé"},
{H:"Maël",F:"Sabrina"},
{H:"Cassandre",F:"Clara"},
{H:"Louis",F:"Charline"},
{H:"Youns",F:"Asma"},
{H:"Martin",F:"Océane"},
{H:"Julien",F:"Anaïs"},
{H:"Timéo",F:"Ophélie"},
{H:"Guilhem",F:"Joëlle"},
{H:"Antoine",F:"Linda"},
{H:"Dorian",F:"Frédérique"},
{H:"Pierre",F:"Caroline"},
{H:"Dimitri",F:"Juliette"},
{H:"Guillaume",F:"Noémie"},
{H:"Paul",F:"Valentine"},
{H:"Esteban",F:"Maéva"},
{H:"Alexandre",F:"Maria"},
{H:"Thibaut",F:"Sophie"},
{H:"Loan",F:"Julie"},
{H:"Clément",F:"Inaya"},
{H:"Robin",F:"Cyrille"},
{H:"Loris",F:"Jade"},
{H:"Joseph",F:"Nell"},
{H:"Sébastien",F:"Sylvie"},
{H:"Yoann",F:"Sandra"},
{H:"Stéphane",F:"Erika"},
{H:"Jean-louis",F:"Carla"}
];