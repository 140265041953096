import { Component, OnInit  } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { AnpaaService } from '../app/services/anpaa.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private anpaaService: AnpaaService,
    public toastCtrl: ToastController,
    public swUpdate: SwUpdate,
    ) {
    this.initializeApp();
  }

  ngOnInit() {

    this.swUpdate.versionUpdates.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Nouvelle version en ligne !',
        position: 'bottom',
        buttons: [{ role: 'cancel', text: 'Recharger' }]
      });
      await toast.present();
      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
    this.swUpdate.checkForUpdate();
    setInterval(() => {
      this.swUpdate.checkForUpdate();
    }, 15 * 60 * 1000);
  }

  initializeApp() {
    this.platform.ready().then(() => {

        // this.statusBar.styleDefault();
        // this.splashScreen.hide();

      this.platform.resize.subscribe(() => {
        this.anpaaService.checkCentre();
      });

    });
  }
}
